import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M16 32a16 16 0 1 0 0-32 16 16 0 0 0 0 32Zm1.86-18.824-2 9.41c-.14.68.058 1.066.608 1.066.388 0 .974-.14 1.372-.492l-.176.832c-.574.692-1.84 1.196-2.93 1.196-1.406 0-2.004-.844-1.616-2.638l1.476-6.936c.128-.586.012-.798-.574-.94l-.902-.162.164-.762 4.58-.574h-.002ZM16 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        fill="#9E5E31"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
