export const inputNumbers: any = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
};

export const dataSolved = [
  [8, 2, 7, 9, 6, 5, 3, 4, 1],
  [1, 5, 4, 3, 2, 7, 6, 8, 9],
  [3, 9, 6, 1, 4, 8, 7, 5, 2],
  [5, 9, 3, 4, 7, 2, 6, 1, 8],
  [4, 6, 8, 5, 1, 3, 9, 7, 2],
  [2, 7, 1, 6, 8, 9, 4, 3, 5],
  [7, 8, 6, 1, 5, 4, 2, 3, 9],
  [2, 3, 5, 7, 9, 6, 8, 4, 1],
  [9, 1, 4, 8, 2, 3, 5, 6, 7],
];

export const sudokuData: { value: number; guess: number[] }[][] = [
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
  ],
  [
    {
      value: 3,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 5,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 7,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 5,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
  ],
  [
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 5,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
];
