interface ICheckInput {
  num: number;
  inputs: number[];
  dataSolved: number[][];
}

const checkInputComplete: (props: ICheckInput) => {
  isComplete: boolean;
  elements: any[];
} = ({ num, inputs, dataSolved }) => {
  const isComplete = inputs[num] + 1 >= 9;
  let elements: any = [];
  if (isComplete) {
    const squares: any = document.querySelectorAll(`.board__item`);

    squares.forEach((square: any, sqrIdx: number) => {
      const elementIdx = dataSolved[sqrIdx].indexOf(num);
      const element = square.children[elementIdx];
      elements.push(element);
    });
  }
  return { isComplete, elements };
};

export default checkInputComplete;
