interface ICheckRow {
  num: number;
  sqr: number;
  item: number;
  sudoku: any;
}
const checkCompleteRow: (props: ICheckRow) => {
  isComplete: boolean;
  elements: any[];
} = ({ num, sqr, item, sudoku }) => {
  const rows = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
  ];

  const getRange = (num: number) => Math.floor(num / 3);
  const sqrRange = rows[getRange(sqr)];
  const itemRange = rows[getRange(item)];
  let completeCells = 0;
  const selectRows: any = [];

  sqrRange.forEach((sqr) => {
    itemRange.forEach((item) => {
      const element = sudoku[sqr][item];
      //remove value from guess of every cell
      sudoku[sqr][item].guess = element.guess.filter((g: number) => g !== num);
      //select element on DOM
      selectRows.push(
        document.querySelector(`.board_item-${sqr}`)?.children[item]
      );
      if (element.value > 0) {
        completeCells++;
      }
    });
  });

  
  return { isComplete: completeCells === 9, elements: selectRows };
};

export default checkCompleteRow;
