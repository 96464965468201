import "./index.css";

import { DownArrow } from "../Icons/Animated/DownArrow";
import { FC } from "react";
import { Info } from "../Icons";

interface IProps {
  timerRef: any;
  setCurrCell: Function;
  setIsMenuHidden: Function;
  setIsInfoHidden: Function;
  setHint: Function;
  multiplier: number;
  score: number;
  hintQuantity: number;
  isHintDisable: boolean;
}
const Controls: FC<IProps> = ({
  timerRef,
  setCurrCell,
  setIsMenuHidden,
  setIsInfoHidden,
  setHint,
  multiplier,
  score,
  hintQuantity,
  isHintDisable,
}) => {
  return (
    <div className="game-controls">
      <button
        className="back-btn"
        onClick={() => {
          timerRef.current?.stopTimer();
          setIsMenuHidden(false);
          setCurrCell(undefined);
        }}
      >
        menu
      </button>
      <span>{score}</span>
      <Info
        style={{ cursor: "pointer" }}
        onClick={() => {
          timerRef.current?.stopTimer();
          setIsInfoHidden(false);
        }}
      />
      <span>x{multiplier}</span>

      <div
        onClick={() => !isHintDisable && setHint()}
        style={{
          height: "45px",
          width: "45px",
          borderRadius: "11px",
          border: "3px solid rgb(158, 95, 49)",
          opacity: `${isHintDisable ? "0.5" : "1"}`,
        }}
        className={`grid__item unselected-cell grid__item--full`}
      >
        {!isHintDisable && (
          <div className="input-hint">
            <div
              className={`input-hint-child  input-hint-active input-hint-child-fixed-size`}
            >
              <DownArrow />
            </div>
          </div>
        )}
        <p
          style={{
            textAlign: "center",
            margin: 0,
          }}
        >
          {hintQuantity > 0 ? hintQuantity : ""}
        </p>
      </div>
    </div>
  );
};

export default Controls;
