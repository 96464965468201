interface ICheckCol {
  num: number;
  sqr: number;
  item: number;
  sudoku: any;
}

const checkCompleteCol: (props: ICheckCol) => {
  isComplete: boolean;
  elements: any[];
} = ({ num, sqr, item, sudoku }) => {
  const cols = [
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
  ];

  const getRange = (num: number) => num % 3;
  const sqrRange = cols[getRange(sqr)];
  const itemRange = cols[getRange(item)];
  let completeCells = 0;
  const selectSquares: any[] = [];

  sqrRange.forEach((sqr) => {
    itemRange.forEach((item) => {
      const element = sudoku[sqr][item];
      //remove value from guess of every cell
      sudoku[sqr][item].guess = element.guess.filter((g: number) => g !== num);
      //select element on DOM
      selectSquares.push(
        document.querySelector(`.board_item-${sqr}`)?.children[item]
      );
      if (element.value > 0) {
        completeCells++;
      }
    });
  });

  return { isComplete: completeCells === 9, elements: selectSquares };
};

export default checkCompleteCol;
