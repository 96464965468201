import "./Sudoku.css";

import { Board, Controls, Instructions, Menu, Modal } from "./components";
import { Pen, Pencil } from "./components/Icons";
import Timer, { TimerHandleType } from "./components/Timer";
import { useEffect, useRef, useState } from "react";

import { Input } from "./Input";
import aplauseSound from "./sounds/aplause.wav";
import checkComplete from "./helpers/checkComplete";
import clapSound from "./sounds/clap.mp3";
import cloneDeep from "lodash/cloneDeep";
import completeSound from "./sounds/complete.mp3";
import correctSound from "./sounds/correct.mp3";
import data from "./data";
import errorSound from "./sounds/error.mp3";
import { saveStats } from "./helpers/saveScore";
import wrongInputSound from "./sounds/wrongInput.mp3";

const difficulty = {
  easy: 20,
  medium: 80,
  hard: 140,
  expert: 180,
};

const errorPenalty = {
  easy: 2,
  medium: 10,
  hard: 20,
  expert: 40,
};

const hintNumber = {
  easy: {
    quantity: 6,
    penalty: 1,
  },
  medium: {
    quantity: 4,
    penalty: 5,
  },
  hard: {
    quantity: 3,
    penalty: 20,
  },
  expert: {
    quantity: 0,
    penalty: 180,
  },
};

export type DifficultyType = "easy" | "medium" | "hard" | "expert";

function Sudoku() {
  const countRef = useRef(0);
  const timerRef = useRef<TimerHandleType>(null);
  const [diff, setDiff] = useState<DifficultyType>("easy");
  const [sudoku, setSudoku] = useState(data[diff].data);
  const [inputType, setInputType] = useState<"value" | "guess">("value");
  const [errorCount, setErrorCount] = useState(0);
  const [score, setScore] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const [hint, setHint] = useState(0);
  const [hintQuantity, setHintQuantity] = useState(hintNumber[diff].quantity);

  const [isInfoHidden, setIsInfoHidden] = useState(true);

  // menu
  const [isMenuHidden, setIsMenuHidden] = useState(false);
  const [menuLocation, setMenuLocation] = useState("menu");
  // end menu

  const [currCell, setCurrCell] = useState<{
    value: number;
    sqr: number;
    item: number;
    coords: { x: number; y: number };
    element?: HTMLElement;
  }>();

  const [inputs, setInputs] = useState<any>([]);
  let audioCorrectRef = useRef<HTMLAudioElement>(null);
  let audioErrorRef = useRef<HTMLAudioElement>(null);
  let audioClapRef = useRef<HTMLAudioElement>(null);
  let audioWrongInputRef = useRef<HTMLAudioElement>(null);
  let audioAplauseRef = useRef<HTMLAudioElement>(null);
  let audioCompleteRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const inputValues = Array.from({ length: 9 }, (_, i) => i + 1).reduce(
      (acc: any, curr) => {
        acc[curr] = 0;
        return acc;
      },
      {}
    );

    let newInputs = inputValues;
    let counter = 0;
    sudoku.forEach((sqr) => {
      sqr.forEach((item) => {
        const { value } = item;
        if (value !== 0) {
          counter++;
          newInputs = { ...newInputs, [value]: newInputs[value] + 1 };
        }
      });
    });
    setInputs(newInputs);
    countRef.current = counter;
  }, [sudoku]);

  useEffect(() => {
    const updateCellLocation = () => {
      const x = currCell?.element?.offsetLeft;
      const y = currCell?.element?.offsetTop;

      setCurrCell((prev: any) => {
        if (!prev) return;
        return { ...prev, coords: { x, y } };
      });
    };

    window.addEventListener("resize", updateCellLocation);
    return () => window.removeEventListener("resize", updateCellLocation);
  }, [currCell]);

  /**
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */

  const handlePenalty = () => {
    const penalty = errorPenalty[diff];
    const newMultiplier = multiplier - penalty >= 1 ? multiplier - penalty : 1;
    setMultiplier(newMultiplier);
  };

  const handleHint = () => {
    if (!currCell) return;
    const currSolved = data[diff].solvedData[currCell?.sqr][currCell?.item];
    setHint(currSolved);

    setTimeout(() => setHint(0), 5000);
    const penalty = hintNumber[diff].penalty;
    const newMultiplier = multiplier - penalty >= 1 ? multiplier - penalty : 1;
    setMultiplier(newMultiplier);
    setHintQuantity((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
  };

  const handleInputValue = (input: string) => {
    if (!currCell) return { isCorrect: false, sudoku };
    const numberInput = parseInt(input);
    const dataSolved = data[diff].solvedData;
    const isCorrect = dataSolved[currCell.sqr][currCell.item] === numberInput;

    let newSudoku = cloneDeep(sudoku);
    if (isCorrect) {
      newSudoku[currCell?.sqr][currCell.item]["value"] = numberInput;
      setInputs({ ...inputs, [numberInput]: inputs[numberInput] + 1 });
      countRef.current += 1;
      const isComplete = checkComplete({
        data: {
          num: numberInput,
          sqr: currCell.sqr,
          item: currCell.item,
        },
        dataSolved,
        inputs,
        playAudio,
        setSudoku,
        sudoku: newSudoku,
        countRef,
      });
      setCurrCell((prev: any) => {
        return { ...prev, value: numberInput };
      });

      setScore((prev: number) => prev + numberInput * multiplier);

      if (isComplete) {
        timerRef.current?.stopTimer();
        saveStats(
          diff,
          score + numberInput * multiplier,
          timerRef.current?.getTime() || ""
        );
      } else {
        playAudio("correct");
      }
      return { isCorrect: true, sudoku: newSudoku };
    }

    setErrorCount((prev) => (prev += 1));
    handlePenalty();

    playAudio("error");
    return { isCorrect: false, sudoku: newSudoku };
  };

  // lista de guess actual y actualizar sudoku
  const handleInputGuess = (input: string) => {
    const newSudoku = cloneDeep(sudoku);
    if (!currCell) return;
    let guesses = sudoku[currCell?.sqr][currCell.item]["guess"];
    const numberInput = parseInt(input);
    const isPresent = guesses.some((e) => e === numberInput);
    if (isPresent) {
      guesses = guesses.filter((e) => e !== numberInput);
    } else {
      guesses = [...guesses, parseInt(input)];
      guesses.sort();
    }
    newSudoku[currCell?.sqr][currCell.item]["guess"] = guesses;
    setSudoku(newSudoku);
  };

  const playAudio = (type: string) => {
    const sourceRef: any = {
      correct: audioCorrectRef,
      error: audioErrorRef,
      clap: audioClapRef,
      wrongInput: audioWrongInputRef,
      complete: audioCompleteRef,
      aplause: audioAplauseRef,
    };

    if (sourceRef[type].current !== null) {
      if (sourceRef[type].current.paused) {
        sourceRef[type].current.play();
      } else {
        sourceRef[type].current.currentTime = 0;
      }
    }
  };

  // celda actual , play audio
  const handleInput = (
    input: string,
    inputType: "value" | "guess",
    element: HTMLElement
  ) => {
    setHint(0);
    // sin celda seleccionada
    if (!currCell) return;
    // celda llena
    if (currCell.value !== 0) {
      playAudio("wrongInput");
      return;
    }

    let isCorrect = false;
    // determinar si el valor es correcto
    if (inputType === "value") {
      const inputRes = handleInputValue(input);
      isCorrect = inputRes.isCorrect;
      setSudoku(inputRes.sudoku);
    } else {
      handleInputGuess(input);
    }

    // Animar input a la posicion adecuada con la animacion
    // correspondiente (correct o incorrect)
    element.style.setProperty(
      "--xOffset",
      (currCell.coords.x - element.offsetLeft).toFixed(2) + "px"
    );
    element.style.setProperty(
      "--yOffset",
      (currCell.coords.y - element.offsetTop).toFixed(2) + "px"
    );

    if (inputType === "value") {
      if (isCorrect) {
        element.classList.add("input--animation-correct");
      } else {
        element.classList.add("input--animation-incorrect");
      }
    }
  };

  /**
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   */

  return (
    <div className="game-container">
      <Controls
        timerRef={timerRef}
        setCurrCell={setCurrCell}
        setIsInfoHidden={setIsInfoHidden}
        setIsMenuHidden={setIsMenuHidden}
        setHint={handleHint}
        multiplier={multiplier}
        score={score}
        hintQuantity={hintQuantity}
        isHintDisable={hintQuantity <= 0 || !currCell || currCell?.value !== 0}
      />

      <audio ref={audioCorrectRef} src={correctSound}></audio>
      <audio ref={audioErrorRef} src={errorSound}></audio>
      <audio ref={audioClapRef} src={clapSound}></audio>

      <audio ref={audioCompleteRef} src={completeSound}></audio>
      <audio ref={audioWrongInputRef} src={wrongInputSound}></audio>
      <audio ref={audioAplauseRef} src={aplauseSound}></audio>
      <Board
        data={sudoku}
        currCell={currCell}
        setCurrCell={setCurrCell}
        timerRef={timerRef}
        playSound={() => {
          playAudio("clap");
        }}
      />
      <div className="indicators">
        <div className="errors">{`errors: ${errorCount}`}</div>
        <Timer
          ref={timerRef}
          updateMultiplier={() => {
            setMultiplier((prev) => (prev > 1 ? prev - 1 : 1));
          }}
        />

        <div
          style={{ width: "32px", height: "32px" }}
          onClick={() =>
            setInputType((prev) => {
              if (prev === "value") return "guess";
              return "value";
            })
          }
        >
          {inputType === "value" ? (
            <Pen viewBox="0 0 32 32" />
          ) : (
            <Pencil viewBox="0 0 32 32" />
          )}
        </div>
      </div>

      <div className="input__container">
        {Object.keys(inputs).map((num: any) => {
          const isEmpty = currCell?.value === 0;
          const isHidden = inputs[num] >= 9;
          let hintActive = false;
          if (currCell) hintActive = Number(num) === hint;
          return (
            <Input
              key={`input-control-${num}`}
              num={num}
              isEmpty={isEmpty}
              isHidden={isHidden}
              hintActive={hintActive}
              type={inputType}
              currGuess={
                currCell ? sudoku[currCell?.sqr][currCell.item]["guess"] : []
              }
              callback={handleInput}
            />
          );
        })}
      </div>

      <Modal
        isHidden={isInfoHidden}
        onClose={(value: boolean) => {
          setIsInfoHidden(value);
          timerRef.current?.startTimer();
        }}
      >
        <Instructions />
      </Modal>

      <Modal
        isHidden={isMenuHidden}
        onClose={() => {
          setMenuLocation("menu");
        }}
      >
        <Menu
          location={menuLocation}
          setLocation={setMenuLocation}
          startGame={(_difficulty: any) => {
            if (_difficulty !== "resume") {
              setDiff(_difficulty);
              const newSudoku = cloneDeep(
                data[_difficulty as DifficultyType].data
              );
              setSudoku(newSudoku);
              setScore(0);
              setErrorCount(0);
              //@ts-ignore
              setHintQuantity(hintNumber[_difficulty].quantity);
              //@ts-ignore
              setMultiplier(difficulty[_difficulty]);
              timerRef.current?.resetTimer();
            }
            setIsMenuHidden(true);
            setMenuLocation("menu");
          }}
        />
      </Modal>
    </div>
  );
}

export default Sudoku;
