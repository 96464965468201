import "./index.css";

import { FC, useEffect, useState } from "react";

import { getStats } from "../../helpers/getScores";
import useDelayMount from "../../hooks/useDelayMount";
import useDelayUnmount from "../../hooks/useDelayUnmount";

interface IProps {
  location: string;
  setLocation: Function;
  startGame: (difficulty: string) => void;
}

export const Menu: FC<IProps> = ({ location, setLocation, startGame }) => {
  const showDiff = useDelayMount({
    isMounted: location === "difficulties",
    delayTime: 5000,
  });

  return (
    <div className="menu-container">
      <h1 className="menu-title">Sudoku</h1>
      {/* <div className={`options-container ${location}`}> */}
      <div className={`options-container`}>
        {location === "menu" && (
          <MenuOptions onClick={(value) => setLocation(value)} />
        )}
        {location === "difficulties" && (
          <Difficulties
            onClick={(value) => {
              startGame(value);
            }}
          />
        )}
        {location === "stats" && <Stats />}
        {location === "about" && <About />}
      </div>
    </div>
  );
};

interface IMenuProps {
  onClick: (id: string) => void;
}

const MenuOptions: FC<IMenuProps> = ({ onClick }) => {
  const options = [
    { name: "Play", location: "difficulties" },
    { name: "Stats", location: "stats" },
    // { name: "Instructions", location: "instructions" },
    { name: "About", location: "about" },
  ];
  return (
    <ul
      className="menu-options"
      onClick={(event: any) => {
        event.stopPropagation();
        const location = event.target.getAttribute("data-location");
        location && onClick(location);
      }}
    >
      {options.map(({ name, location }) => {
        return (
          <li
            key={location}
            data-location={location}
            className="menu-option-item"
          >
            {name}
          </li>
        );
      })}
    </ul>
  );
};

const Difficulties: FC<IMenuProps> = ({ onClick }) => {
  const options = [
    { name: "Easy", location: "easy" },
    { name: "Medium", location: "medium" },
    { name: "Hard", location: "hard" },
    { name: "Expert", location: "expert" },
    { name: "Resume", location: "resume" },
  ];
  return (
    <ul
      className="menu-options"
      onClick={(event: any) => {
        event.stopPropagation();
        const location = event.target.getAttribute("data-location");
        location && onClick(location);
      }}
    >
      {options.map(({ name, location }) => {
        return (
          <li
            key={location}
            data-location={location}
            className="menu-option-item"
          >
            {name}
          </li>
        );
      })}
    </ul>
  );
};

const Stats = () => {
  const Temp: FC<{ level: string; bestScore?: number; bestTime?: string }> = ({
    level,
    bestScore,
    bestTime,
  }) => {
    const formatNumber = (value?: number) => {
      if (!value) return;
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    return (
      <div>
        <div className="level">
          <h3 className="title" style={{ textTransform: "capitalize" }}>
            {level}
          </h3>

          <div className="score">
            <p>
              best score:{" "}
              <span className={`${bestScore ? "" : "empty"}`}>
                {formatNumber(bestScore) ?? "none"}
              </span>
            </p>
            <p>
              best time: <span>{bestTime}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };
  const stats = getStats();
  return (
    <div className="level-container">
      {stats.map((stat) => (
        <Temp
          key={`stats-container-${stat.level}${stat.time}`}
          level={stat.level}
          bestScore={stat.score}
          bestTime={stat.time}
        />
      ))}
    </div>
  );
};

const Instructions = () => {
  return <p>Instructions</p>;
};

const About = () => {
  return (
    <div className="about">
      <p> Jesús Guadalupe Mezquití Guerrero</p>
      <a href="">LinkedIn</a>
      <p>Game reference</p>
      <a href="https://apps.apple.com/us/app/id366247306">
        Sudoku 2 PeopleFun CG,LLC
      </a>
    </div>
  );
};
