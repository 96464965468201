interface ICheckSquare {
  num: number;
  sqr: number;

  sudoku: any;
}
const checkCompleteSqr: (props: ICheckSquare) => {
  isComplete: boolean;
  elements: any[];
} = ({ num, sqr, sudoku }) => {
  let completeCells = 0;
  let elements: any[] = [];

  sudoku[sqr].forEach((item: any, index: number) => {
    sudoku[sqr][index].guess = item.guess.filter((g: number) => g !== num);
    if (sudoku[sqr][index].value > 0) {
      completeCells++;
    }
  });

  if (completeCells === 9) {
    const selectSquares: any = document.querySelector(
      `.board_item-${sqr}`
    )?.children;
    elements = Array.from(selectSquares);
  }
  return { isComplete: completeCells === 9, elements };
};

export default checkCompleteSqr;
