import { DifficultyType } from "../Sudoku";
const difficulties: DifficultyType[] = ["easy", "medium", "hard", "expert"];

export const getStats = () => {
  return difficulties.map((diff) => {
    const diffStatsStr = localStorage.getItem(`sudoku-stats-${diff}`);
    if (diffStatsStr) {
      let json = JSON.parse(diffStatsStr);
      json.level = diff;
      return json;
    }
    return { score: 0, time: "00:00", level: diff };
  });
};
