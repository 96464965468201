import { DifficultyType } from "../Sudoku";

export const saveStats = (
  diff: DifficultyType,
  score: number,
  time: string
) => {
  const currStatsStr = localStorage.getItem(`sudoku-stats-${diff}`);
  const stats = JSON.stringify({
    score,
    time,
  });
 

  if (!currStatsStr) { 
    localStorage.setItem(`sudoku-stats-${diff}`, stats);
  } else {
    const currStats = JSON.parse(currStatsStr);
    if (currStats.score < score) { 
      localStorage.setItem(`sudoku-stats-${diff}`, stats);
    }
  }
};
