import { FC, useLayoutEffect, useRef } from "react";

interface Props {
  currCell: { value: number; sqr: number; item: number } | undefined;
  setCurrCell: any;
  cell: { value: number; guess: number[] };
  sqr: number;
  item: number;
  startTimer: Function;
  playSound: Function;
}
export const Cell: FC<Props> = ({
  currCell,
  setCurrCell,
  cell,
  sqr,
  item,
  startTimer,
  playSound,
}) => {
  const { value, guess } = cell;

  const isSelected =
    (value === currCell?.value && value !== 0) ||
    (currCell && value === 0 && guess.includes(currCell?.value));
  const isEmpty = value === 0;
  const isEmptySelected =
    isEmpty && currCell?.sqr === sqr && currCell?.item === item;

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const quantity = guess.length < 7 ? 2 : 3;
    const basis = guess.length < 5 ? "50%" : "33.3333%";

    ref.current?.setAttribute(
      "style",
      `
    --guess-quantity:${quantity};
    --guess-quantity-basis:${basis};
    `
    );

    ref.current?.setAttribute("background", "red");
  }, [guess]);

  return (
    <div
      ref={ref}
      className={`grid__item unselected-cell
        ${!isEmpty ? "grid__item--full" : ""}
        ${isSelected ? "selected-cell-value" : ""}
        ${isEmptySelected ? "selected-cell" : ""}
        `}
      onClick={(event) => {
        const { offsetTop: y, offsetLeft: x } = event.currentTarget;
        playSound();
        setCurrCell({
          value,
          sqr,
          item,
          coords: { x, y },
          element: event.currentTarget,
        });
        startTimer();
      }}
      onAnimationEnd={(event) => {
        event.currentTarget.classList.remove("item--animation-complete");
      }}
    >
      {value === 0 ? (
        guess.map((guess: number) => {
          return <Guess value={guess} />;
        })
      ) : (
        <p
          style={{
            textAlign: "center",
            margin: 0,
          }}
        >
          {value}
        </p>
      )}
    </div>
  );
};

const Guess: FC<{ value: number }> = ({ value }) => {
  return (
    <div key={`input--guest-${value}`} className="input__guess">
      {value}
    </div>
  );
};
