import "./index.css";

import { Cell } from "../Cell";
import { FC } from "react";

interface IProps {
  data: any;
  currCell: any;
  setCurrCell: Function;
  timerRef: any;
  playSound: Function;
}
const Board: FC<IProps> = ({
  data,
  currCell,
  setCurrCell,
  timerRef,
  playSound,
}) => {
  const startTimer = () => {
    timerRef.current?.startTimer();
  };

  return (
    <div className="board">
      {data.map((squareValues: any, squareIdx: number) => {
        return (
          <div
            key={`board-item-${squareIdx}`}
            className={`board__item board_item-${squareIdx}`}
          >
            {squareValues.map((cellValue: any, cellIdx: number) => {
              return (
                <Cell
                  key={`board-cell-${squareIdx}-${cellIdx}`}
                  currCell={currCell}
                  setCurrCell={setCurrCell}
                  cell={cellValue}
                  sqr={squareIdx}
                  item={cellIdx}
                  startTimer={startTimer}
                  playSound={playSound}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Board;
