import "./DownArrow.css";

const DownArrow = () => {
  return (
    <svg
      className="size"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <defs>
        <filter id="glow">
          <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0
                0 10 0 0 0
                0 0 20 0 0
                0 0 0 50 -5"
            result="glow"
          />
          <feComposite in="SourceGraphic" in2="glow" operator="over" />
        </filter>
      </defs>
      <polygon
        // points="50,90 10,10 90,10"
        points="50,85 5,10 95,10"
        fill="#e6e6e6"
        stroke="#0000f0"
        strokeWidth="4"
        filter="url(#glow)"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="scale"
          values="1; 0.9; 1"
          dur="1s"
          repeatCount="indefinite"
        />
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="translate"
          values="0 0; 0 30; 0 0"
          dur="1s"
          repeatCount="indefinite"
        />
      </polygon>
    </svg>
  );
};

export default DownArrow;
