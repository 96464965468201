import * as React from "react";

const SvgComponent = (props: any) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m30.648 2.302-.95-.951a4.615 4.615 0 0 0-6.525 0L3.957 20.568.148 30.527c-.446 1.178.15 1.772 1.325 1.323l9.96-3.805L30.648 8.827a4.613 4.613 0 0 0 0-6.525M18.58 7.362l1.337 1.337L6.71 21.907 5.372 20.57 18.58 7.361m2.045 2.045 1.97 1.971L9.388 24.587l-1.97-1.97 13.207-13.21ZM4.814 29.504l-2.316-2.318 2.24-5.835 5.91 5.908-5.834 2.245m6.62-2.873-1.337-1.338 13.206-13.208 1.337 1.338L11.433 26.63m13.978-13.979-6.061-6.06 1.508-1.508 6.06 6.06-1.507 1.508Z"
      fill="#9E5E31"
    />
  </svg>
);

export default SvgComponent;
