import { useEffect, useState } from "react";
type IProps = {
  isMounted: boolean;
  delayTime: number;
};
const useDelayMount = ({ isMounted, delayTime }: IProps) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeroutId: NodeJS.Timeout;

    if (!isMounted && show) setShow(false);
    else if (isMounted && !show)
      timeroutId = setTimeout(() => setShow(true), delayTime);
    return () => clearTimeout(timeroutId);
  }, [isMounted, delayTime, show]);
  return show;
};

export default useDelayMount;
