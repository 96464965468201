import "./index.css";

import { FC, PropsWithChildren, useEffect, useState } from "react";

import ReactDOM from "react-dom";

interface IProps {
  isHidden: boolean;
  onClose: Function;
}

export const Modal: FC<PropsWithChildren<IProps>> = ({
  children,
  isHidden,
  onClose,
}) => {
  const [loaded, setLoaded] = useState(false);
  const element = document.getElementById("modal");
  useEffect(() => {
    const root = document.getElementById("root");

    if (isHidden) {
      if (!root?.classList.contains("in")) return;
      root?.classList.remove("in");
      root?.classList.add("out");
    } else {
      !loaded && setLoaded(true);
      root?.classList.remove("out");
      root?.classList.add("in");
    }
  }, [isHidden]);

  return element
    ? ReactDOM.createPortal(
        <div
          id="modal-container"
          className={`${loaded ? (isHidden ? "out" : "in") : ""}`}
          onClick={() => {
            onClose(true);
          }}
        >
          <div className="modal-background ">
            <div className="modal">{children}</div>
          </div>
        </div>,
        element
      )
    : null;
};
