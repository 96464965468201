import { dataSolved as DSEasy, sudokuData as SDEasy } from "./data-easy";
import { dataSolved as DSExpert, sudokuData as SDExpert } from "./data-expert";
import { dataSolved as DSHard, sudokuData as SDHard } from "./data-hard";
import { dataSolved as DSMedium, sudokuData as SDMedium } from "./data-medium";

export default {
  easy: {
    data: SDEasy,
    solvedData: DSEasy,
  },
  medium: {
    data: SDMedium,
    solvedData: DSMedium,
  },
  hard: {
    data: SDHard,
    solvedData: DSHard,
  },
  expert: {
    data: SDExpert,
    solvedData: DSExpert,
  },
};
