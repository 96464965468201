import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { formatNumber } from "../../helpers";
import { useTimer } from "../../hooks";

type TimerPropsType = {
  updateMultiplier: () => void;
};

export type TimerHandleType = {
  startTimer: () => void;
  stopTimer: () => void;
  resetTimer: () => void;
  getTime: () => string;
};

const Timer = forwardRef<TimerHandleType, TimerPropsType>(
  ({ updateMultiplier }, ref) => {
    const { seconds, start, stop, reset, isActive } = useTimer();
    const [lastTime, setLastTime] = useState(0);

    useImperativeHandle(ref, () => ({
      startTimer() {
        start();
      },
      stopTimer() {
        stop();
      },
      resetTimer() {
        reset();
      },
      getTime() {
        return getTimerStr();
      },
    }));

    useEffect(() => {
      if (seconds - lastTime >= 20) {
        setLastTime(seconds);
        updateMultiplier();
      }
    }, [updateMultiplier, setLastTime, seconds, lastTime]);

    const getTimerStr = () => {
      const sec = seconds % 60;
      const min = Math.floor(seconds / 60) % 60;
      const hour = Math.floor(seconds / 3600);

      const secStr = formatNumber(sec, 2);
      const minStr = formatNumber(min, 2);
      const hourStr = formatNumber(hour, 2);

      let result =
        hour > 0 ? `${hourStr}:${minStr}:${secStr}` : `${minStr}:${secStr}`;
      if (!isActive) result = `(${result})`;

      return result;
    };

    return <div className="timer">{getTimerStr()}</div>;
  }
);

export default Timer;
