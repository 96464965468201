export const inputNumbers: any = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
};

export const dataSolved = [
  [3, 2, 6, 7, 9, 8, 4, 5, 1],
  [9, 7, 8, 1, 5, 4, 6, 2, 3],
  [5, 1, 4, 3, 6, 2, 9, 8, 7],
  [8, 1, 3, 2, 6, 4, 9, 7, 5],
  [2, 6, 5, 3, 9, 7, 8, 4, 1],
  [4, 7, 9, 1, 5, 8, 2, 3, 6],
  [5, 4, 9, 6, 3, 2, 1, 8, 7],
  [7, 1, 6, 5, 8, 9, 4, 3, 2],
  [8, 2, 3, 7, 4, 1, 6, 9, 5],
];

export const sudokuData: { value: number; guess: number[] }[][] = [
  [
    {
      value: 3,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 5,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 7,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 7,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 5,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 7,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 5,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 5,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 7,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
];
