export const inputNumbers: any = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
};

export const dataSolved = [
  [2, 6, 4, 8, 3, 7, 1, 5, 9],
  [9, 1, 7, 5, 2, 4, 2, 6, 8],
  [8, 5, 3, 6, 9, 1, 7, 2, 4],
  [6, 7, 1, 5, 4, 8, 3, 9, 2],
  [2, 3, 9, 1, 7, 6, 8, 4, 5],
  [5, 4, 8, 2, 3, 9, 1, 6, 7],
  [7, 8, 5, 4, 2, 3, 9, 1, 6],
  [4, 9, 2, 6, 8, 1, 7, 5, 3],
  [3, 1, 6, 9, 7, 5, 4, 8, 2],
];

export const sudokuData: { value: number; guess: number[] }[][] = [
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
  ],
  [
    {
      value: 9,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 3,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 4,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 5,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 2,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 9,
      guess: [],
    },
    {
      value: 1,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
  [
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 6,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
    {
      value: 8,
      guess: [],
    },
    {
      value: 0,
      guess: [],
    },
  ],
];
