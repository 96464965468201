import checkCompleteCol from "./checkCompleteCol";
import checkCompleteRow from "./checkCompleteRow";
import checkCompleteSqr from "./checkCompleteSquare";
import checkInputComplete from "./checkInputComplete";

interface ICheck {
  playAudio: Function;
  countRef: any;
  inputs: number[];
  dataSolved: number[][];
  sudoku: any;
  setSudoku: Function;
  data: {
    num: number;
    sqr: number;
    item: number;
  };
}

const checkComplete: (props: ICheck) => boolean = ({
  data,
  playAudio,
  countRef,
  inputs,
  dataSolved,
  sudoku,
  setSudoku,
}) => {
  const { num, sqr, item } = data;

  const cRow = checkCompleteRow({ num, sqr, item, sudoku });
  const cCol = checkCompleteCol({ num, sqr, item, sudoku });
  const cSqr = checkCompleteSqr({ num, sqr, sudoku });
  const cInput = checkInputComplete({ num, inputs, dataSolved });
  let isComplete = false;

  if (
    cRow.isComplete ||
    cCol.isComplete ||
    cSqr.isComplete ||
    cInput.isComplete
  ) {
    let elements: any[] = [];
    if (cRow.isComplete) elements = [...elements, ...cRow.elements];
    if (cCol.isComplete) elements = [...elements, ...cCol.elements];
    if (cSqr.isComplete) elements = [...elements, ...cSqr.elements];
    if (cInput.isComplete) elements = [...elements, ...cInput.elements];

    setTimeout(() => {
      playAudio("complete");
      elements.forEach((element) => {
        if (!element.classList.contains("item--animation-complete")) {
          element.classList.add("item--animation-complete");
        }
      });
    }, 800);
  }

  if (countRef.current >= 81) {
    isComplete = true;
    setTimeout(() => {
      playAudio("aplause");
      const board = document.querySelector(".board");
      const items = board?.querySelectorAll("div.grid__item");
      items?.forEach((element) => {
        element.classList.add("item--animation-complete");
      });
    }, 2000);
  }
  // }, 1000);
  return isComplete;
};

export default checkComplete;
