import * as React from "react";

const SvgComponent = (props: any) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.292 1.292a1 1 0 0 1 1.416 0l8 8a1 1 0 0 1 0 1.416l-3.804 3.804-1.658 6.626a3 3 0 0 1-2.048 2.146l-20.69 6.208L8.716 8.8a3 3 0 0 1 2.144-2.046l6.626-1.656 3.806-3.806Zm-3.6 5.816-6.346 1.586a1 1 0 0 0-.716.684l-5.14 17.13 17.134-5.14a1 1 0 0 0 .68-.714l1.588-6.348-7.2-7.2v.002Z"
      fill="#9E5E31"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.664 26.456 16 18a2 2 0 1 0-2-2L5.544 26.336l-.052.172.172-.052Z"
      fill="#9E5E31"
    />
  </svg>
);

export default SvgComponent;
