import DownArrow from "./components/Icons/Animated/DownArrow/DownArrow";

export const Input: React.FC<{
  isEmpty: boolean;
  isHidden: boolean;
  num: string;
  type: string;
  callback: Function;
  currGuess: number[];
  hintActive: boolean;
}> = ({ isEmpty, isHidden, num, type, callback, currGuess, hintActive }) => {
  return (
    <>
      <div
        onAnimationEnd={(event) => {
          event.currentTarget.classList.remove("input--animation-correct");
          event.currentTarget.classList.remove("input--animation-incorrect");
        }}
        onClick={(event) => {
          return callback(num, type, event.currentTarget);
        }}
        className={`input__number 
                ${type === "guess" ? "input__number--guess" : ""} 
                ${
                  currGuess.includes(parseInt(num)) && type === "guess"
                    ? "input__number--used"
                    : ""
                } 
                ${!isEmpty && type === "guess" ? "input__number-disabled" : ""}
                
                ${isHidden ? "input__number--hidden" : ""} `}
      >
        <div className="input-hint">
          <div
            className={`input-hint-child ${
              hintActive ? "input-hint-active" : ""
            }`}
          >
            <DownArrow />
          </div>
        </div>
        {num}
      </div>
    </>
  );
};
