import "./index.css";

import { Pen, Pencil } from "../Icons";

import { DownArrow } from "../Icons/Animated/DownArrow";

export const Instructions = () => {
  return (
    <div className={`instructions-container`}>
      <div className="rule-container">
        <div className="rule-number">{"1)"}</div>
        <div className={`grid__item`}>
          <p
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            {}
          </p>
        </div>
        <p className="rule-description">Touch an empty slot...</p>
      </div>
      <div className="rule-container">
        <div className="rule-number">{"2)"}</div>
        <div className={`grid__item grid__item--full `}>
          <p
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            {2}
          </p>
        </div>
        <p className="rule-description">
          ...then touch a tile to place it on the slot
        </p>
      </div>
      <div className="rule-container">
        <div className={`grid__item grid__item--full selected-cell-value`}>
          <p
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            {3}
          </p>
        </div>
        <p className="rule-description">Touch a tile to highlight numbers</p>
      </div>
      <div className="rule-container">
        {/* <div className={`grid__item unselected-cell grid__item--full`}>
          <p
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            {1}
          </p>
        </div> */}
        <div
          style={{
            height: "50px",
            width: "80px",
            position: "relative",
            display: "flex",
          }}
        >
          <Pen viewBox="0 0 32 32" />
          <Pencil viewBox="0 0 32 32" />
        </div>
        <p className="rule-description">
          Tap the pencil to switch to note taking and back
        </p>
      </div>
      <div className="rule-container">
        <div
          style={{
            height: "45px",
            width: "45px",
            borderRadius: "11px",
            border: "3px solid rgb(158, 95, 49)",
            backgroundColor: "transparent",
          }}
          className={`grid__item unselected-cell grid__item--full`}
        >
          <div className="input-hint">
            <div
              style={{ left: "auto", right: "-10px" }}
              className={`input-hint-child  input-hint-active`}
            >
              <DownArrow />
            </div>
          </div>

          <p
            style={{
              textAlign: "center",
              margin: 0,
            }}
          >
            {""}
          </p>
        </div>
        <p className="rule-description">Touch for hint</p>
      </div>
    </div>
  );
};
