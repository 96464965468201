import { useEffect, useState } from "react";

const useTimer = () => {
  const [isActive, setIsActive] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    //clear timer on unmount
    return () => timerId && clearInterval(timerId);
  }, [timerId]);

  const start = () => {
    if (isActive) return;
    const temp_timerId = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    setTimerId(temp_timerId);
    setIsActive(true);
  };

  const stop = () => {
    if (!isActive) return;
    setIsActive(false);
    if (!timerId) return;
    clearInterval(timerId);
  };

  const reset = () => {
    stop();
    setSeconds(0);
  };

  return {
    isActive,
    seconds,
    start,
    stop,
    reset,
  };
};

export default useTimer;
